<template>
  <div class="numberComments">
    <div class="left">
      <span class="number">{{ commentNumber }}</span>
      <span>条评论</span>
    </div>

    <div class="right">
      <span @click="goList(1)">{{ likeCount }}赞</span>
      |
      <span @click="goList(2)">{{ shareCount }}转发</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "commentNum",

  props: {
    // 评论条数
    commentNumber: {
      type: [Number, String],
      default: 0,
    },
    // 点赞数
    likeCount: {
      type: [Number, String],
      default: 0,
    },
    // 转发数
    shareCount: {
      type: [Number, String],
      default: 0,
    },
    // 文章id
    id: {
      type: [Number, String],
      default: 0,
    },
    // 是否为药品
    platform: {
      type: [Number, String],
      default: "",
    },
  },

  mounted() {},

  methods: {
    goList(index) {
      if (this.platform) {
        this.$router.push(
          "/shareLike?index=" +
            index +
            "&likeCount=" +
            this.likeCount +
            "&shareCount=" +
            this.shareCount +
            "&id=" +
            this.id +
            "&platform=" +
            this.platform
        );
      } else {
        this.$router.push(
          "/shareLike?index=" +
            index +
            "&likeCount=" +
            this.likeCount +
            "&shareCount=" +
            this.shareCount +
            "&id=" +
            this.id
        );
      }
    },
  },
};
</script>


<style lang="scss" scoped>
.numberComments {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #333333;
  font-size: 24px;
  margin-top: 30px;
  padding-bottom: 16px;
  box-sizing: border-box;
  border-bottom: 1px solid #f2f2f2;
  width: 100%;
  justify-content: space-between;

  > .left {
    > .number {
      font-size: 30px;
      color: #e60012;
      margin-right: 5px;
    }
  }

  > .right {
    font-size: 18px;
    color: #8f8f8f;
    margin-right: 5px;

    > span {
      cursor: pointer;
    }
  }
}
</style>